<template>
  <div class="admin flexB">
    <Lnb />

    <router-view></router-view>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
import Lnb from "@/components/Lnb";
export default {
  components: { Lnb },
  mixins: [format, setNavbar, setFooter],
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState(["navVisible", "footerVisible"]),
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
